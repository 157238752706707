.FAQ {
  display: flex;
  justify-content: center;
}

.FAQ-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  width: 90%;
  margin: 96px 0;
}
